<template>
  <fieldset>
    <DocFieldsZu
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <DateField
          :modelValue="modelValue.egrzDate"
          @update:modelValue="update('egrzDate', $event)"
        >
          <label class="label">Дата размещения в ЕГРЗ</label>
        </DateField>
      </template>
    </DocFieldsZu>
  </fieldset>
</template>

<script>
import DocEGRZ from "../../../models/documents/docEGRZ";
import DocFieldsZu from "./DocFieldsZu";
import DateField from "../../basic/form/DateField";
import { toRefs } from "vue";
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocEGRZ(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFieldsZu, DateField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    return { data, update };
  },
};
</script>